import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <section>
        <h2>1. Information We Collect</h2>
        <p>We may collect personal information such as your name, email address, and device information when you use our app. We also collect non-personal information such as usage data and analytics.</p>
      </section>
      <section>
        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect to provide and improve our services, personalize your experience, and communicate with you. We may also use the information for research and analytics purposes.</p>
      </section>
      <section>
        <h2>3. Information Sharing</h2>
        <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted partners who assist us in operating our app and conducting our business.</p>
      </section>
      <section>
        <h2>4. Data Security</h2>
        <p>We implement reasonable security measures to protect your information from unauthorized access, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure.</p>
      </section>
      <section>
        <h2>5. Your Choices</h2>
        <p>You have the right to access, update, or delete your personal information. You can also opt-out of receiving promotional communications from us. Please contact us using the information provided below to exercise your choices.</p>
      </section>
      <section>
        <h2>6. Changes to this Privacy Policy</h2>
        <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.</p>
      </section>
      <section>
        <h2>7. Contact Us</h2>
        <p>If you have any questions or concerns about our privacy policy, please contact us at privacy@histochat.com.</p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;