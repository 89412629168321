import React from 'react';
import './Home.css';
import appStoreBadge from '../assets/general/app-store.svg';
import playStoreBadge from '../assets/general/google-play.svg';

function Home() {
  return (
    <div className="home">
      <section className="hero">
        <div className="hero-content">
          <h1>Discover Personalized Audio Biographies</h1>
          <p>Explore the lives of historical figures through captivating audio stories and engage in interactive quizzes and AI chats.</p>
          <div className="cta-buttons">
            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
              <img src={appStoreBadge} alt="Download on the App Store" className="app-store-badge" />
            </a>
            <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
              <img src={playStoreBadge} alt="Get it on Google Play" className="app-store-badge" />
            </a>
          </div>
        </div>
      </section>

      <section className="features">
        <div className="feature">
          <i className="fas fa-headphones-alt"></i>
          <h2>Audio Biographies</h2>
          <p>Listen to engaging audio biographies of notable historical figures, narrated by professional voice actors.</p>
        </div>
        <div className="feature">
          <i className="fas fa-question-circle"></i>
          <h2>Interactive Quizzes</h2>
          <p>Test your knowledge and learn more about the historical figures through interactive quizzes.</p>
        </div>
        <div className="feature">
          <i className="fas fa-robot"></i>
          <h2>AI Chats</h2>
          <p>Engage in fascinating conversations with AI-powered chatbots that emulate historical figures.</p>
        </div>
      </section>
    </div>
  );
}

export default Home;