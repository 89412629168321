import React from 'react';
import './TermsAndConditions.css';

function TermsAndConditions() {
  return (
    <div className="terms-and-conditions">
      <h1>Terms and Conditions</h1>
      <section>
        <h2>1. Introduction</h2>
        <p>These terms and conditions govern your use of our mobile application. By accessing or using our app, you agree to be bound by these terms and conditions.</p>
      </section>
      <section>
        <h2>2. Intellectual Property</h2>
        <p>All intellectual property rights in the app and its content belong to us. You may not use, reproduce, or distribute any part of the app without our prior written consent.</p>
      </section>
      <section>
        <h2>3. User Conduct</h2>
        <p>You agree to use the app only for lawful purposes and in accordance with these terms and conditions. You must not use the app in any way that causes, or may cause, damage to the app or impairment of its availability or accessibility.</p>
      </section>
      <section>
        <h2>4. Disclaimer of Warranties</h2>
        <p>The app is provided "as is" without any representations or warranties, express or implied. We do not warrant that the app will be error-free, uninterrupted, or free from harmful components.</p>
      </section>
      <section>
        <h2>5. Limitation of Liability</h2>
        <p>In no event shall we be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with your use of the app.</p>
      </section>
      <section>
        <h2>6. Governing Law</h2>
        <p>These terms and conditions shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</p>
      </section>
    </div>
  );
}

export default TermsAndConditions;